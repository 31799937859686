@import './colors.scss';
@import './animation.scss';

.juridico-filters {
  height: 100%;
  min-width: 200px;
  width: 220px;
}

.juridico-base {
  width: calc(100% - 240px);
}

.btnAbas {
  margin: 20px 0;
  border-bottom: 1px double #0000001a;
  margin-bottom: 15px;
  padding-bottom: 30px;
}

.labelBtn {
  text-transform: uppercase;
  font-weight: 700;
}

.juridico-card {
  border-top: 2px solid $primary;
  margin: 0 10px;
  padding: 10px;
  margin-top: 23px;
  width: 100%;
}

.juridico-card .label {
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
}

.juridico-card .value {
  font-size: 10px;
  font-weight: 400;
}

.juridico-card .valueH1 {
  font-size: 15px;
  font-weight: 100;
}

.juridico-card .valueH2 {
  font-size: 12px;
  font-weight: 300;
}

.juridico-block {
  margin: 1px 5px;
  padding: 4px;
  display: inline-block;
}

.juridico-classes {
  border: 2px solid $primary;
  font-weight: 900 !important;
  display: inline-block;
  color: $primary;
  padding: 5px;
  font-size: 10px;
  position: relative;
  top: -23px;
  background: white;
  margin-top: 30px;
}

.juridico-parte {
  background: #00b0a0ed;
  border-radius: 5px;
  padding: 3px 10px;
  margin: 1px;
  margin-left: 0;
  color: white;
  font-weight: 600;
  font-size: 11px;
  display: inline-block;
}

.txtMostrarPartes {
  font-size: 9px;
  position: relative;
  top: -10px;
  left: 10px;
  font-weight: 500;
}

.active_visualization {
  background: #c5c9c6;
}

.score {
  max-width: 100px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.score .label {
  text-align: center;
  margin-bottom: 10px;
}

.CircularProgressbar-text {
  color: black;
  fill: black !important;
  font-weight: 900;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: black !important;
}

.divisor-vertical {
  border-right: 1px solid #bddad0;
  display: inline-block;
  height: 24px;
  width: 1px;
  margin: 0 10px;
  position: relative;
  top: 7px;
  left: 5px;
}

// table
.tableJustica > thead > tr > th {
  text-transform: uppercase;
  font-size: 10px;
}
.tableJustica > thead > tr > th:nth-child(1) {
  width: 140px;
  font-size: 10px;
}
.tableJustica > tbody > tr > th {
  font-size: 10px;
}

.tableJustica > tbody > tr > td:nth-child(2) {
  width: 30px;
  text-align: center;
  font-size: 10px;
  font-weight: 800;
}

.tableJustica > tbody > tr > td:nth-child(3) {
  width: 30px;
  text-align: center;
  font-size: 10px;
  font-weight: 800;
}

.tableJustica > thead > tr > th:nth-child(4) {
  width: 180px;
  text-align: center;
  font-size: 10px;
}

.tableJustica > tbody > tr > th:nth-child(5) {
  width: 250px;
  text-align: center;
  font-size: 10px;
}

.tableJustica > tbody > tr > th {
  border-right: 1px solid $primary;
}

.tableJustica > tbody > tr > td:nth-child(2) {
  border-right: 1px solid $primary;
}

.tableJustica > tbody > tr > td:nth-child(3) {
  border-right: 1px solid $primary;
}

.tableJustica > tbody > tr > td:nth-child(4) {
  border-right: 1px solid $primary;
  font-size: 10px;
}

.tableJustica > tbody > tr > td:nth-child(5) {
  border-right: 1px solid $primary;
  font-size: 10px;
}

.tableJustica > tbody > tr > td:nth-child(6) {
  border-right: 1px solid $primary;
  font-size: 10px;
}

.tableJustica > tbody > tr > td:nth-child(7) {
  border-right: 1px solid $primary;
  text-align: center;
  font-size: 10px;
}

.tableJustica > tbody > tr > td:nth-child(8) {
  text-align: center;
  font-size: 10px;
}

.noItemChecked {
  text-transform: uppercase;
  font-weight: 400;
  border: 1px solid;
  display: inline-block;
  padding: 16px;
  opacity: 0.5;
}

// timeline
.timeline-base {
  width: 100%;
}

.timeline-content {
  padding: 20px;
  padding-left: 30px;
  min-height: 230px;
  // padding-bottom: 70px;
}

.timeline-item {
  margin: 10px 0px 0px 0px;
  width: 20%;
}

.timeline-classificacao {
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2em;
  text-transform: lowercase;
  color: white;
  border: 2px solid black;
  background-color: #001e64;
  border-bottom-width: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.timeline-desc {
  border-radius: 5px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 10px 10px 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2em;
  background: white;
  text-transform: lowercase;
  color: black;
  border: 2px solid black;
  overflow-wrap: anywhere;
  border-bottom-width: 0px;
}

.timeline-date {
  text-align: center;
  padding: 8px;
  font-weight: 700;
  top: -40px;
  border-top: 1px solid #0000001c;
  border-radius: 0;
  color: #1b1b1b;
  background: #e8e8e8;
  border: 2px solid;
  border-radius: 0px 0px 10px 10px;
}

.timeline-line {
  float: left;
  margin-top: 10px;
  color: #3f3f3f;
}

.timeline-container {
  width: 90%;
  float: left;
}

.timeline-circle {
  width: 20px;
  height: 20px;
  background: $blue;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: 5px;
}

.section-name {
  font-weight: 800;
  font-size: 16px;
  margin-top: 9px;
  background: linear-gradient(90deg, $secondary, transparent 40%);
  padding: 4px 11px;
}

.timeline-toolbar {
  border-top: 1px solid $secondary;
}

.juridico-partes {
  width: 37%;
}

.parte-title {
  font-size: 15px;
  font-weight: 600;
  margin-top: 10px;
  color: $primary;
}

.parte-content {
  margin: 0;
  padding: 0;
}
.parte-card {
  background: #00000010;
  display: block;
  font-size: 12px;
  margin: 10px 10px 10px 0;
  padding: 5px;
}

.parte-divisor {
  border-bottom: 2px solid $primary;
  height: 2px;
  margin: 20px 0;
}

.parte-card-left {
  background: linear-gradient(90deg, #dedede, transparent) !important;
  font-weight: 400;
}

.parte-card-right {
  background: linear-gradient(90deg, #dedede, transparent) !important;
  font-weight: 400;
}

.juridico-detalhes-div {
  background: linear-gradient(180deg, #017553 -60%, transparent);
  width: 20% !important;
}

.noProcess {
  background: $secondary;
  padding: 10px;
  margin: 10px 0;
  font-weight: 300;
}

.juridico-detalhes-label {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 10px;
}

.juridico-detalhes-value {
  font-size: 15px;
  font-weight: 600;
}

.juridico-detalhes-card {
  margin-bottom: 10px;
  margin-top: 20px;
}

.juridico-divisor {
  // border-top: 1px dashed #00000052;
  margin: 50px 0 30px;
  padding-top: 20px;
}

.juridico-divisor-box {
  position: relative;
  display: inline-block;
  padding: 10px;
  color: #1c1c1c;
  font-weight: 700;
  text-transform: uppercase;
  // margin-top: 5px;
  font-size: 1em;
  padding-left: 10px;
}

.juridico-divisor-icon {
  background: #252827;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: 0 auto;
  padding-top: 19px;
  margin-top: 30px;
  color: #24b6a7;
}

.juridico-divisor-nup {
  margin-top: 0;
  position: relative;
  top: -10px;
  font-weight: 700;
  padding-left: 10px;
}

.juridico-divisor-uf {
  margin-top: 20px;
  margin-left: 6px;
  font-size: 30px;
  padding-top: 11px;
  font-weight: 700;
}

.juridico-divisor-boxH1 {
  font-size: 1.5em;
  margin: 0;
  margin-top: 20px;
  font-weight: 700;
  padding-left: 10px;
}

#contentRoot
  > div.col-12.baseChildren.row
  > div
  > div.rows.juridico-base
  > div.row.justify-content-start.juridico-divisor
  > div.col-3
  > div
  > div
  > div.value
  > svg
  > path.CircularProgressbar-path {
  stroke: #000000 !important;
}

#contentRoot
  > div.col-12.baseChildren.row
  > div
  > div.rows.juridico-base
  > div.row.justify-content-start.juridico-divisor
  > div.col-12.row.justify-content-center.juridico-scores-box
  > div
  > div.value
  > svg
  > path.CircularProgressbar-path {
  stroke: black !important;
}

#contentRoot
  > div.col-12.baseChildren.row
  > div
  > div.rows.juridico-base
  > div.row.justify-content-start.juridico-divisor
  > div.col-3
  > div
  > div
  > div.value
  > svg
  > path.CircularProgressbar-trail {
  stroke: #00000020 !important;
}

#contentRoot
  > div.col-12.baseChildren.row
  > div
  > div.rows.juridico-base
  > div.row.justify-content-start.juridico-divisor
  > div.col-3
  > div
  > div
  > div.value
  > svg
  > text {
  color: black !important;
  fill: black !important;
}

.btnCleanSelected {
  max-width: 53px;
  position: relative;
  top: -40px;
  padding: 10px;
  opacity: 0.5;
}

.btnCleanSelected:hover {
  opacity: 1;
}

.btnCleanSelected span {
  margin-left: 10px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 10px;
}

.btnRemoveSelected {
  background: $primary;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 20px;
  margin-bottom: 20px;
}
